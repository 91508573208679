// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.fKKsPvMriby2gVyGcN6J{width:100%;height:100%}.w8XR2IwzybnvlX1BE9jz{position:relative;margin-top:20px;margin-bottom:20px}`, "",{"version":3,"sources":["webpack://./src/App.module.scss"],"names":[],"mappings":"AAAA,sBACE,UAAA,CACA,WAAA,CAGF,sBACE,iBAAA,CACA,eAAA,CACA,kBAAA","sourcesContent":[".mainWrapper {\n  width: 100%;\n  height: 100%;\n}\n\n.container {\n  position: relative;\n  margin-top: 20px;\n  margin-bottom: 20px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"mainWrapper": `fKKsPvMriby2gVyGcN6J`,
	"container": `w8XR2IwzybnvlX1BE9jz`
};
export default ___CSS_LOADER_EXPORT___;
