import { useEffect, useRef, useState } from 'react';
import { Paper, Table, Tooltip, Grid, Title } from '@mantine/core';
import * as d3 from 'd3';
import './StakeholdersTable.css'; // Import the CSS file

// Define the TypeScript type for the stakeholder data
type Stakeholder = {
    name: string;
    stake: number;
    type: string;
};

// Define the constant with the stakeholder data
const stakeholders: Stakeholder[] = [
    { name: '(hidden)', stake: 16500, type: 'Shares' },
    { name: '(hidden)', stake: 4000, type: 'Shares' },
    { name: '(hidden)', stake: 4000, type: 'Shares' },
    { name: '0x8C5F8824d9fBf02599083FF7b13555bB670B4f60', stake: 2808.37, type: 'Token' },
    { name: '(hidden)', stake: 500, type: 'Shares' },
    { name: '0x70ff0A110Deb3d66baf14058477f7Cfd3e61536c', stake: 185.19, type: 'Token' },
    { name: '0x398b77a19aaf35845AB2B30e7a7a35102afC8952', stake: 10, type: 'Token' },
    { name: '0x7C9fb4C554b0eF8854123791E70bd55e8C5D3ecf', stake: 1, type: 'Token' },
];

// Constants for calculations
const circulatingTokens = 3004.56;
const circulatingShares = 25000;
const circulatingSupply = circulatingShares + circulatingTokens;
const maximumSupply = 37500;
const maximumTokens = 12500;
const availableForPrivateOffers = maximumTokens - circulatingTokens;

// Helper function to calculate profit share
const calculateProfitShare = (stake: number) => {
    const percentage = (stake / circulatingSupply) * 100;
    return {
        formatted: percentage < 0.01 ? percentage.toFixed(3) + '%' : percentage < 0.1 ? percentage.toFixed(2) + '%' : percentage.toFixed(1) + '%',
        full: percentage.toFixed(5) + '%'
    };
};

// Helper function to calculate fully diluted share
const calculateFullyDiluted = (stake: number) => {
    const percentage = (stake / maximumSupply) * 100;
    return {
        formatted: percentage < 0.01 ? percentage.toFixed(3) + '%' : percentage < 0.1 ? percentage.toFixed(2) + '%' : percentage.toFixed(1) + '%',
        full: percentage.toFixed(5) + '%'
    };
};

// Helper function to shorten on-chain addresses
const shortenAddress = (address: string) => {
    if (address.startsWith('0x') && address.length > 8) {
        return `${address.slice(0, 6)}...${address.slice(-4)}`;
    }
    return address;
};

// Component to render the pie chart
const PieChart = ({ data, onHover, hoveredIndex }: { data: Stakeholder[], onHover: (index: number | null) => void, hoveredIndex: number | null }) => {
    const ref = useRef<SVGSVGElement | null>(null);

    useEffect(() => {
        const svg = d3.select(ref.current);
        const width = 300;
        const height = 300;
        const radius = 125;

        const color = d3.scaleSequential(d3.interpolateGreens)
            .domain([-2500, d3.max(data, d => d.stake) * 0.6 || 1]);

        const pie = d3.pie<Stakeholder>().value(d => d.stake);
        const arc = d3.arc<d3.PieArcDatum<Stakeholder>>()
            .innerRadius(0)
            .outerRadius(radius);

        const arcs = pie(data);

        svg.attr('width', width)
            .attr('height', height)
            .selectAll('*').remove();

        const g = svg.append('g')
            .attr('transform', `translate(${width / 2}, ${height / 2})`);

        const paths = g.selectAll('path')
            .data(arcs)
            .enter()
            .append('path')
            .attr('d', arc as any)
            .attr('fill', d => color(d.data.stake))
            .attr('stroke', '#f8f8f8')
            .attr('stroke-width', 1)
            .attr('class', d => `arc-${d.index}`);

        paths.transition()
            .duration(200)
            .attr('transform', d => {
                if (hoveredIndex === d.index) {
                    const [x, y] = arc.centroid(d);
                    const angle = Math.atan2(y, x);
                    const offset = 10;
                    return `translate(${Math.cos(angle) * offset}, ${Math.sin(angle) * offset})`;
                }
                return 'translate(0, 0)';
            });

        paths.on('mouseover', (event, d) => {
            onHover(d.index);
        })
            .on('mouseout', () => {
                onHover(null);
            });

        svg.on('click', (event) => {
            const [x, y] = d3.pointer(event);
            const distance = Math.sqrt(Math.pow(x - width / 2, 2) + Math.pow(y - height / 2, 2));
            if (distance > radius) {
                onHover(null);
            }
        });

    }, [data, onHover, hoveredIndex]);

    return <svg ref={ref}></svg>;
};

export const StakeholdersTable = () => {
    const [hoveredIndex, setHoveredIndex] = useState<number | null>(null);

    useEffect(() => {
        const handleClickOutside = (event: MouseEvent) => {
            const target = event.target as HTMLElement;
            if (!target.closest('.stakeholders-content')) {
                setHoveredIndex(null);
            }
        };

        document.addEventListener('click', handleClickOutside);
        return () => {
            document.removeEventListener('click', handleClickOutside);
        };
    }, []);

    return (
        <Paper p="md" className="stakeholders-table">
            <Title order={3} mb="sm">Stakeholders</Title>
            <div className="stakeholders-content">
                <div className="pie-chart-container">
                    <PieChart data={stakeholders} onHover={setHoveredIndex} hoveredIndex={hoveredIndex} />
                </div>
                <div className="table-container">
                    <Table>
                        <thead>
                            <tr>
                                <th>Stakeholder</th>
                                <th># Stake</th>
                                <th>Type</th>
                                <th>% Profit Share</th>
                                <th>% Fully Diluted</th>
                            </tr>
                        </thead>
                        <tbody>
                            {stakeholders.map((stakeholder, index) => (
                                <tr
                                    key={index}
                                    className={hoveredIndex === index ? 'highlight' : ''}
                                    onMouseEnter={() => setHoveredIndex(index)}
                                    onMouseLeave={() => setHoveredIndex(null)}
                                >
                                    <td>
                                        {stakeholder.name.startsWith('0x') ? (
                                            <span>{shortenAddress(stakeholder.name)}</span>
                                        ) : (
                                            <Tooltip label="Login as Stakeholder to view" withArrow>
                                                <i>{shortenAddress(stakeholder.name)}</i>
                                            </Tooltip>
                                        )}
                                    </td>
                                    <td>{stakeholder.stake.toLocaleString()}</td>
                                    <td>{stakeholder.type}</td>
                                    <td>
                                        <Tooltip label={calculateProfitShare(stakeholder.stake).full} withArrow>
                                            <strong>{calculateProfitShare(stakeholder.stake).formatted}</strong>
                                        </Tooltip>
                                    </td>
                                    <td>
                                        <Tooltip label={calculateFullyDiluted(stakeholder.stake).full} withArrow>
                                            {calculateFullyDiluted(stakeholder.stake).formatted}
                                        </Tooltip>
                                    </td>
                                </tr>
                            ))}
                            <tr>
                                <td colSpan={1}><i>Available for private offers</i></td>
                                <td>{availableForPrivateOffers.toLocaleString()}</td>
                                <td>Token</td>
                                <td>-</td>
                                <td>
                                    <Tooltip label={(availableForPrivateOffers / maximumSupply * 100).toFixed(5) + '%'} withArrow>
                                        {(availableForPrivateOffers / maximumSupply * 100).toFixed(1) + '%'}
                                    </Tooltip>
                                </td>
                            </tr>
                        </tbody>
                    </Table>
                </div>
            </div>
        </Paper>
    );
};