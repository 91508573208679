import { useEffect, useRef, useState } from 'react';
import { Paper, Table, Tooltip, Title } from '@mantine/core';
import * as d3 from 'd3';
import './ExpensesTable.css'; // Import the CSS file

// Define the TypeScript types for the cost
type MonthlyPrice = { type: 'monthly', monthlyCost: number, dailyCost: number, currency: 'USD' | 'EUR' };
type DailyPrice = { type: 'daily', dailyCost: number, monthlyCost: number, currency: 'USD' | 'EUR' };

// Update the Expense type to use the union type for cost
type Expense = {
    service: string;
    supplier: string;
    cost: MonthlyPrice | DailyPrice;
};

// Helper function to compute costs
const computeCosts = (type: 'monthly' | 'daily', amount: number, currency: 'USD' | 'EUR'): MonthlyPrice | DailyPrice => {
    if (type === 'monthly') {
        return { type, monthlyCost: amount, dailyCost: amount / 30, currency };
    } else {
        return { type, dailyCost: amount, monthlyCost: amount * 30, currency };
    }
};

// Update the constant with the expense data
const expenses: Expense[] = [
    { service: 'Cloud Compute', supplier: 'Google', cost: computeCosts('daily', 7.09, 'EUR') },
    { service: 'Archive Storage', supplier: 'Google', cost: computeCosts('daily', 4.48, 'EUR') },
    { service: 'Postal Address', supplier: 'Wechselpilot', cost: computeCosts('monthly', 125.00, 'EUR') },
    { service: 'AI Code Editor', supplier: 'Cursor', cost: computeCosts('monthly', 40, 'USD') },
    { service: 'ChatGPT Plus', supplier: 'OpenAI', cost: computeCosts('monthly', 20, 'USD') },
    { service: 'Email Hosting', supplier: 'Ionos', cost: computeCosts('monthly', 15.00, 'EUR') },
    { service: 'Google Workspace User', supplier: 'Google', cost: computeCosts('monthly', 8, 'USD') },
    { service: 'Password Manager', supplier: 'Bitwarden', cost: computeCosts('monthly', 8, 'USD') },
];

// Component to render the pie chart
const PieChart = ({ data, onHover, hoveredIndex }: { data: Expense[], onHover: (index: number | null) => void, hoveredIndex: number | null }) => {
    const ref = useRef<SVGSVGElement | null>(null);

    useEffect(() => {
        const svg = d3.select(ref.current);
        const width = 300;
        const height = 300;
        const radius = 125;

        const color = d3.scaleSequential(d3.interpolateReds)
            .domain([-35, d3.max(data, d => d.cost.monthlyCost) || 1]);

        const pie = d3.pie<Expense>().value(d => d.cost.monthlyCost);
        const arc = d3.arc<d3.PieArcDatum<Expense>>()
            .innerRadius(0)
            .outerRadius(radius);

        const arcs = pie(data);

        svg.attr('width', width)
            .attr('height', height)
            .selectAll('*').remove();

        const g = svg.append('g')
            .attr('transform', `translate(${width / 2}, ${height / 2})`);

        const paths = g.selectAll('path')
            .data(arcs)
            .enter()
            .append('path')
            .attr('d', arc as any)
            .attr('fill', d => color(d.data.cost.monthlyCost))
            .attr('stroke', 'white')
            .attr('stroke-width', 2)
            .attr('class', d => `arc-${d.index}`);

        paths.transition()
            .duration(200)
            .attr('transform', d => {
                if (hoveredIndex === d.index) {
                    const [x, y] = arc.centroid(d);
                    const angle = Math.atan2(y, x);
                    const offset = 10;
                    return `translate(${Math.cos(angle) * offset}, ${Math.sin(angle) * offset})`;
                }
                return 'translate(0, 0)';
            });

        paths.on('mouseover', (event, d) => {
            onHover(d.index);
        })
            .on('mouseout', () => {
                onHover(null);
            });

        svg.on('click', (event) => {
            const [x, y] = d3.pointer(event);
            const distance = Math.sqrt(Math.pow(x - width / 2, 2) + Math.pow(y - height / 2, 2));
            if (distance > radius) {
                onHover(null);
            }
        });

    }, [data, onHover, hoveredIndex]);

    return <svg ref={ref}></svg>;
};

export const ExpensesTable = () => {
    const [hoveredIndex, setHoveredIndex] = useState<number | null>(null);

    useEffect(() => {
        const handleClickOutside = (event: MouseEvent) => {
            const target = event.target as HTMLElement;
            if (!target.closest('.expenses-content')) {
                setHoveredIndex(null);
            }
        };

        document.addEventListener('click', handleClickOutside);
        return () => {
            document.removeEventListener('click', handleClickOutside);
        };
    }, []);

    return (
        <Paper p="md" className="expenses-table">
            <Title order={3} mb="sm">Expenses</Title>
            <div className="expenses-content">
                <div className="pie-chart-container">
                    <PieChart data={expenses} onHover={setHoveredIndex} hoveredIndex={hoveredIndex} />
                </div>
                <div className="table-container">
                    <Table>
                        <thead>
                            <tr>
                                <th>Service</th>
                                <th>Supplier</th>
                                <th>Currency</th>
                                <th>Monthly</th>
                                <th>Daily</th>
                            </tr>
                        </thead>
                        <tbody>
                            {expenses.map((expense, index) => (
                                <tr
                                    key={index}
                                    className={hoveredIndex === index ? 'highlight' : ''}
                                    onMouseEnter={() => setHoveredIndex(index)}
                                    onMouseLeave={() => setHoveredIndex(null)}
                                >
                                    <td>{expense.service}</td>
                                    <td>{expense.supplier}</td>
                                    <td>{expense.cost.currency}</td>
                                    <td>{expense.cost.type === 'monthly' ? <strong>{expense.cost.monthlyCost.toFixed(2)}</strong> : <i>{`(${expense.cost.monthlyCost.toFixed(2)})`}</i>}</td>
                                    <td>{expense.cost.type === 'daily' ? <strong>{expense.cost.dailyCost.toFixed(2)}</strong> : <i>{`(${expense.cost.dailyCost.toFixed(2)})`}</i>}</td>
                                </tr>
                            ))}
                        </tbody>
                    </Table>
                </div>
            </div>
        </Paper>
    );
};