import { Paper, Text, Title, Group, Stack } from '@mantine/core';

export const Registration = () => (
    <Paper p="md">
        <Title order={3} mb="sm">Registration</Title>
        <Stack spacing="xs">
            <Group align="flex-start" noWrap>
                <Text size="sm" color="dimmed" style={{ fontStyle: 'italic', width: '70px' }}>Name:</Text>
                <Text>RABOT CRYPTO GmbH</Text>
            </Group>
            <Group align="flex-start" noWrap>
                <Text size="sm" color="dimmed" style={{ fontStyle: 'italic', width: '70px' }}>Address:</Text>
                <Text>
                    <a
                        href="https://www.openstreetmap.org/way/58599892"
                        target="_blank"
                        rel="noopener noreferrer"
                        style={{
                            color: 'inherit',
                            textDecoration: 'none',
                            cursor: 'pointer'
                        }}
                    >
                        Reimersbrücke 5, 20457 Hamburg
                    </a>
                </Text>
            </Group>
            <Group align="flex-start" noWrap>
                <Text size="sm" color="dimmed" style={{ fontStyle: 'italic', width: '70px' }}>Register:</Text>
                <Text>
                    <a
                        href="https://www.northdata.com/RABOT+CRYPTO+GmbH,+Hamburg/HRB+171920"
                        target="_blank"
                        rel="noopener noreferrer"
                        style={{
                            color: 'inherit',
                            textDecoration: 'none',
                            cursor: 'pointer'
                        }}
                    >
                        HRB 171920, Hamburg
                    </a>
                </Text>
            </Group>
        </Stack>
    </Paper>
);