import { Paper, Text, Title, List, Anchor } from '@mantine/core';
import { IconArrowRight } from '@tabler/icons-react';

export const Documents = () => (
    <Paper p="md">
        <Title order={3} mb="sm">Profit Share Token</Title>
        <List
            spacing="xs"
            size="sm"
            icon={<IconArrowRight size={24} />}
        >
            <List.Item>View the <Anchor href="/documents/RABOT-CRYPTO_Token-Shareholders-Agreement.pdf" target="_blank" rel="noopener noreferrer" color="blue">Shareholders Agreement</Anchor> granting rights to token holders</List.Item>
            <List.Item>Inspect the <Anchor href="https://etherscan.io/token/0xd586D47f880AC1a18337932010005B175ACea41E" target="_blank" rel="noopener noreferrer" color="blue">Ethereum Smart Contract</Anchor></List.Item>
            <List.Item>Study the <Anchor href="/documents/RABOT-CRYPTO_Token-Holders-Rights.pdf" target="_blank" rel="noopener noreferrer" color="blue">Token Holders Rights</Anchor></List.Item>
            <List.Item>Read the <Anchor href="/documents/RABOT-CRYPTO_General-Investment-Terms.pdf" target="_blank" rel="noopener noreferrer" color="blue">General Investment Terms</Anchor></List.Item>
        </List>
    </Paper>
);