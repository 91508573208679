// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Fe4VIsv1fhZ0mdlMU7bQ{height:100%;display:flex;flex-direction:column;justify-content:center}`, "",{"version":3,"sources":["webpack://./src/components/AboutText.module.scss"],"names":[],"mappings":"AAAA,sBACI,WAAA,CACA,YAAA,CACA,qBAAA,CACA,sBAAA","sourcesContent":[".aboutText {\n    height: 100%;\n    display: flex;\n    flex-direction: column;\n    justify-content: center;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"aboutText": `Fe4VIsv1fhZ0mdlMU7bQ`
};
export default ___CSS_LOADER_EXPORT___;
