import { Paper, Text, Title, Stack, Box, Anchor } from '@mantine/core';
import styles from './AboutText.module.scss';

export const AboutText = () => (
    <Paper p="md" className={styles.aboutText}>
        <Box sx={{ display: 'flex', flexDirection: 'column', height: '100%' }} p="md">
            <Title order={3} mb="md">About RABOT CRYPTO</Title>
            <Stack spacing="md" sx={{ flex: 1 }}>
                <Text>
                    RABOT CRYPTO is an autonomous proprietary trading firm.
                </Text>
                <Text>
                    Trading profits are paid out to all <Anchor href="https://etherscan.io/token/tokenholderchart/0xd586D47f880AC1a18337932010005B175ACea41E" target="_blank" rel="noopener noreferrer" color="blue">RABOT token holders</Anchor>.
                </Text>
                <Text>
                    The internally developed trading infrastructure uses structured causal models to fully automatically decide on the best world model and the most +EV trading decision at any given time.
                </Text>
                <Text>
                    Given the abstract nature of our trading algorithm, we naturally focus on trading strategies within the <Anchor href="https://constellationnetwork.io/" target="_blank" rel="noopener noreferrer" color="blue">Constellation Network</Anchor> ecosystem and generalize our trading infrastructure from there outwards.
                </Text>
                <Text>
                    Once we open up the platform for more open collaboration, anybody can submit their world models to earn a stake in the success of RABOT.
                </Text>
                <Text>
                    A detailed track record of RABOTs trading activities will be published here shortly.
                </Text>
                <Text>
                    For inquiries, reach out to us at <Anchor href="mailto:bruno@rabot-crypto.com" color="blue">bruno@rabot-crypto.com</Anchor>.
                </Text>
            </Stack>
        </Box>
    </Paper>
);